/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

function Ajouter({ tokenUtile }) {
  const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;

  const navig = useNavigate();
  const [mobile11, SetMobile11] = useState(window.innerWidth < 501);
  useEffect(() => {
    const hundlesSize = () => {
      SetMobile11(window.innerWidth < 501);
    };
    window.addEventListener("resize", hundlesSize);
    return () => {
      window.removeEventListener("resize", hundlesSize);
    };
  }, []);

  const [mobile, SetMobile] = useState(window.innerWidth <= 640);
  const [boutLoading, setboutLoading] = useState(false);

  useEffect(() => {
    const hundleSize = () => {
      SetMobile(window.innerWidth <= 640);
    };
    window.addEventListener("resize", hundleSize);
    return () => {
      window.removeEventListener("resize", hundleSize);
    };
  }, []);

  const [ordinateur, setBlocs] = useState([
    {
      image: "",
      name: "",
      brand: "",
      model: "",
      type: "",
      cpu: "",
      ram: "",
      storage: "",
      os: "",
      screen_size: "",
      resolution: "",
      connectivity: "",
      battery: "",
      weight: "",
      serial_number: "",
      preview: "",
    },
  ]);

  const addBloc = () => {
    setBlocs([
      ...ordinateur,
      {
        image: "",
        name: "",
        brand: "",
        type: "",
        model: "",
        cpu: "",
        ram: "",
        storage: "",
        os: "",
        screen_size: "",
        resolution: "",
        connectivity: "",
        battery: "",
        weight: "",
        serial_number: "",
        preview: "",
      },
    ]);
  };

  const removeBloc = (index) => {
    const newBlocs = [...ordinateur];
    newBlocs.splice(index, 1);
    setBlocs(newBlocs);
  };

  const inputRefs = useRef([]);
  if (inputRefs.current.length !== ordinateur.length) {
    inputRefs.current = Array(ordinateur.length)
      .fill({})
      .map((_, index) => ({
        image: React.createRef(null),
        name: React.createRef(null),
        brand: React.createRef(null),
        model: React.createRef(null),
        type: React.createRef(null),
        ram: React.createRef(null),
        cpu: React.createRef(null),
        storage: React.createRef(null),
        os: React.createRef(null),
        screen_size: React.createRef(null),
        resolution: React.createRef(null),
        connectivity: React.createRef(null),
        battery: React.createRef(null),
        weight: React.createRef(null),
        serial_number: React.createRef(null),
      }));
  }

  const handleInputChange = (index, field, value) => {
    const newBlocs = [...ordinateur];
    newBlocs[index][field] = value;

    if (field === "image") {
      newBlocs[index].preview = value;
    }
    setBlocs(newBlocs);
    if (inputRefs.current[index][field].current) {
      inputRefs.current[index][field].current.focus();
      return false;
    }
  };

  const validateBloc = (bloc, index) => {
    const requiredFields = [
      "image",
      "name",
      "brand",
      "model",
      "type",
      "cpu",
      "ram",
      "storage",
      "os",
      "screen_size",
      "resolution",
      "connectivity",
      "battery",
      "weight",
      "serial_number",
      "preview",
    ];

    for (const field of requiredFields) {
      if (!bloc[field]) {
        toast.warning(
          <div>
            Le{" "}
            <strong>
              {field === "image"
                ? "image"
                : field === "name"
                ? "nom complet"
                : field === "brand"
                ? "marque"
                : field === "model"
                ? "modèle"
                : field === "type"
                ? "type"
                : field === "cpu"
                ? "processeur"
                : field === "ram"
                ? "RAM"
                : field === "storage"
                ? "stockage"
                : field === "os"
                ? "système d'exploitation"
                : field === "screen_size"
                ? "taille d'écran"
                : field === "resolution"
                ? "résolution"
                : field === "connectivity"
                ? "connectivité"
                : field === "battery"
                ? "batterie"
                : field === "weight"
                ? "poids"
                : field === "serial_number"
                ? "numéro de série"
                : field === "preview"
                ? "aperçu"
                : null}
            </strong>{" "}
            est obligatoire au bloc {index + 1}.
          </div>
        );
        if (inputRefs.current[index][field].current) {
          inputRefs.current[index][field].current.focus();
          return false;
        }
      }
    }

    return true;
  };

  const navigate = useNavigate();
  function toRoman(num) {
    const romanNumerals = [
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
      "X",
      "XI",
      "XII",
      "XIII",
      "XIV",
      "XV",
      "XVI",
      "XVII",
      "XVIII",
      "XIX",
      "XX",
      "XXI",
      "XXII",
      "XXIII",
      "XXIV",
      "XXV",
      "XXVI",
      "XXVII",
      "XXVIII",
      "XXIX",
      "XXX",
    ];
    return romanNumerals[num - 1] || num.toString();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    for (let index = 0; index < ordinateur.length; index++) {
      if (!validateBloc(ordinateur[index], index)) {
        return;
      }
    }

    setboutLoading(true);
    const formData = new FormData();

    ordinateur.forEach((pla, index) => {
      if (ordinateur.length > 1) {
        formData.append(`images`, pla.image);
      } else {
        formData.append(`image`, pla.image);
      }

      formData.append(`ordinateur[${index}][name]`, pla.name);
      formData.append(`ordinateur[${index}][brand]`, pla.brand);
      formData.append(`ordinateur[${index}][model]`, pla.model);
      formData.append(`ordinateur[${index}][type]`, pla.type);
      formData.append(`ordinateur[${index}][ram]`, pla.ram);
      formData.append(`ordinateur[${index}][storage]`, pla.storage);
      formData.append(`ordinateur[${index}][cpu]`, pla.cpu);
      formData.append(`ordinateur[${index}][os]`, pla.os);
      formData.append(`ordinateur[${index}][screen_size]`, pla.screen_size);
      formData.append(`ordinateur[${index}][resolution]`, pla.resolution);
      formData.append(`ordinateur[${index}][connectivity]`, pla.connectivity);
      formData.append(`ordinateur[${index}][battery]`, pla.battery);
      formData.append(`ordinateur[${index}][weight]`, pla.weight);
      formData.append(`ordinateur[${index}][serial_number]`, pla.serial_number);
    });

    axios
      .post(
        "https://bakend-tp-app-web-telecom.abahs-jobconnect.online/ordinateur/resgister_ordi",
        formData,
        tokenUtile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((rep) => {
        toast.success("Enregistrement réussi !");
        setboutLoading(false);
        navigate("/");
      })
      .catch((error) => {
        setboutLoading(false);
        console.log(
          "Erreur lors de l'enregistrement de ordinateurPrive =======>:",
          error
        );
        toast.error("Erreur lors de l'ajout de l'ordinateur");
      });
  };

  return (
    <div className={`w-full overflow-y-auto   overflow-x-hidden h-[90vh]`}>
      <div className="w-full flex justify-start">
        <div
          onClick={() => navig("/")}
          className="text-[blue] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="flex flex-col items-center">
        <div className="rounded-lg p-2   w-full   sm:p-4">
          {ordinateur.map((bloc, index) => (
            <>
              <div
                key={index}
                className={`justify-center w-full rounded-xl p-1 sm:p-2  flex flex-col`}
              >
                <div className="w-full">
                  <div className="h-max w-full">
                    <div className="px-3 w-full">
                      <div className="w-full flex items_center justify-between">
                        <label className="block text-xs mt-5  font-[700]  first-letter:uppercase tracking_wide text-grey-darker sm:text-lg mb-1">
                          {toRoman(index + 1)}. Ordinateurs
                        </label>
                        {index > 0 && (
                          <button
                            type="button"
                            className="text-red-500 px-1 max-sm:text-xs py-2"
                            onClick={() => removeBloc(index)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-trash3-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                            </svg>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mb-5">
                  <label
                    htmlFor={`image_${index}`}
                    className="w-full border cursor-pointer bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[blue]"
                  >
                    Image
                  </label>
                  <input
                    type="file"
                    hidden
                    id={`image_${index}`}
                    onChange={(e) =>
                      handleInputChange(index, "image", e.target.files[0])
                    }
                    placeholder="URL de l'image"
                    ref={inputRefs.current[index].image}
                  />
                </div>

                {bloc.preview && (
                  <div>
                    <img
                      src={URL.createObjectURL(bloc.preview)}
                      className="h-20 w-20 object-contain"
                      alt="  "
                    />
                  </div>
                )}
                <div className="grid grid-cols-2 mb-5 gap-2">
                  <div className="flex flex-col">
                    <label
                      htmlFor={`name_${index}`}
                      className="block first:uppercase tracking-wide text-lg mb-1"
                    >
                      Nom
                    </label>
                    <input
                      type="text"
                      id={`name_${index}`}
                      value={bloc.name}
                      onChange={(e) =>
                        handleInputChange(index, "name", e.target.value)
                      }
                      placeholder="Le nom de l'ordinateur"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[blue]"
                      ref={inputRefs.current[index].name}
                    />
                  </div>

                  <div className="flex flex-col mb-5">
                    <label
                      htmlFor={`brand_${index}`}
                      className="block first:uppercase tracking-wide text-lg mb-1"
                    >
                      Brand
                    </label>
                    <input
                      type="tel"
                      id={`brand_${index}`}
                      value={bloc.brand}
                      onChange={(e) =>
                        handleInputChange(index, "brand", e.target.value)
                      }
                      placeholder="Le brand de l'ordinateur"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[blue]"
                      ref={inputRefs.current[index].brand}
                    />
                  </div>

                  <div className="flex flex-col mb-5">
                    <label
                      htmlFor={`model_${index}`}
                      className="block first:uppercase tracking-wide text-lg mb-1"
                    >
                      Model
                    </label>
                    <input
                      id={`model_${index}`}
                      value={bloc.model}
                      onChange={(e) =>
                        handleInputChange(index, "model", e.target.value)
                      }
                      placeholder="Model de l'ordinateur"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[blue]"
                      ref={inputRefs.current[index].model}
                    />
                  </div>
                  <div className="flex flex-col mb-5">
                    <label
                      htmlFor={`type_${index}`}
                      className="block first:uppercase tracking-wide text-lg mb-1"
                    >
                      Type
                    </label>

                    <select
                      type="text"
                      id={`type_${index}`}
                      value={bloc.type}
                      onChange={(e) =>
                        handleInputChange(index, "type", e.target.value)
                      }
                      placeholder="Type de l'ordinateur"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[blue]"
                      ref={inputRefs.current[index].type}
                    >
                      <option hidden value="">
                        Choisissez le type
                      </option>
                      <option className="text-black" value="Bureau">
                        Bureau
                      </option>
                      <option className="text-black" value="Portable">
                        Portable
                      </option>
                    </select>
                  </div>
                  <div className="flex flex-col mb-5">
                    <label
                      htmlFor={`cpu_${index}`}
                      className="block first:uppercase tracking-wide text-lg mb-1"
                    >
                      CPU
                    </label>
                    <input
                      id={`cpu_${index}`}
                      value={bloc.cpu}
                      onChange={(e) =>
                        handleInputChange(index, "cpu", e.target.value)
                      }
                      className="w-full border bg-transparent opacity-95  border-gray-300 rounded-md px-2 py-3 focus:border-[blue] outline-none"
                      ref={inputRefs.current[index].cpu}
                    />
                  </div>

                  <div className="flex flex-col mb-5">
                    <label
                      htmlFor={`ram_${index}`}
                      className="block first:uppercase tracking-wide text-lg mb-1"
                    >
                      RAM
                    </label>
                    <input
                      type="number"
                      id={`ram_${index}`}
                      value={bloc.ram}
                      onChange={(e) =>
                        handleInputChange(index, "ram", e.target.value)
                      }
                      placeholder="Capacité de RAM (en Go)"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[blue]"
                      ref={inputRefs.current[index].ram}
                    />
                  </div>

                  <div className="flex flex-col mb-5">
                    <label
                      htmlFor={`storage_${index}`}
                      className="block first:uppercase tracking-wide text-lg mb-1"
                    >
                      Stockage
                    </label>
                    <input
                      type="text"
                      id={`storage_${index}`}
                      value={bloc.storage}
                      onChange={(e) =>
                        handleInputChange(index, "storage", e.target.value)
                      }
                      placeholder="Type et capacité de stockage"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[blue]"
                      ref={inputRefs.current[index].storage}
                    />
                  </div>

                  <div className="flex flex-col mb-5">
                    <label
                      htmlFor={`os_${index}`}
                      className="block first:uppercase tracking-wide text-lg mb-1"
                    >
                      Système d'exploitation
                    </label>
                    <input
                      type="text"
                      id={`os_${index}`}
                      value={bloc.os}
                      onChange={(e) =>
                        handleInputChange(index, "os", e.target.value)
                      }
                      placeholder="Système d'exploitation"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[blue]"
                      ref={inputRefs.current[index].os}
                    />
                  </div>

                  <div className="flex flex-col mb-5">
                    <label
                      htmlFor={`screen_size_${index}`}
                      className="block first:uppercase tracking-wide text-lg mb-1"
                    >
                      Taille d'écran
                    </label>
                    <input
                      type="number"
                      id={`screen_size_${index}`}
                      value={bloc.screen_size}
                      onChange={(e) =>
                        handleInputChange(index, "screen_size", e.target.value)
                      }
                      placeholder="Taille d'écran (en pouces)"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[blue]"
                      ref={inputRefs.current[index].screen_size}
                    />
                  </div>

                  <div className="flex flex-col mb-5">
                    <label
                      htmlFor={`resolution_${index}`}
                      className="block first:uppercase tracking-wide text-lg mb-1"
                    >
                      Résolution
                    </label>
                    <input
                      type="text"
                      id={`resolution_${index}`}
                      value={bloc.resolution}
                      onChange={(e) =>
                        handleInputChange(index, "resolution", e.target.value)
                      }
                      placeholder="Résolution de l'écran"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[blue]"
                      ref={inputRefs.current[index].resolution}
                    />
                  </div>

                  <div className="flex flex-col mb-5">
                    <label
                      htmlFor={`connectivity_${index}`}
                      className="block first:uppercase tracking-wide text-lg mb-1"
                    >
                      Connectivité
                    </label>
                    <input
                      type="text"
                      id={`connectivity_${index}`}
                      value={bloc.connectivity}
                      onChange={(e) =>
                        handleInputChange(index, "connectivity", e.target.value)
                      }
                      placeholder="Options de connectivité"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[blue]"
                      ref={inputRefs.current[index].connectivity}
                    />
                  </div>

                  <div className="flex flex-col mb-5">
                    <label
                      htmlFor={`battery_${index}`}
                      className="block first:uppercase tracking-wide text-lg mb-1"
                    >
                      Batterie
                    </label>
                    <input
                      type="text"
                      id={`battery_${index}`}
                      value={bloc.battery}
                      onChange={(e) =>
                        handleInputChange(index, "battery", e.target.value)
                      }
                      placeholder="Informations sur la batterie"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[blue]"
                      ref={inputRefs.current[index].battery}
                    />
                  </div>

                  <div className="flex flex-col mb-5">
                    <label
                      htmlFor={`weight_${index}`}
                      className="block first:uppercase tracking-wide text-lg mb-1"
                    >
                      Poids
                    </label>
                    <input
                      type="number"
                      id={`weight_${index}`}
                      value={bloc.weight}
                      onChange={(e) =>
                        handleInputChange(index, "weight", e.target.value)
                      }
                      placeholder="Poids de l'ordinateur (en kg)"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[blue]"
                      ref={inputRefs.current[index].weight}
                    />
                  </div>

                  <div className="flex flex-col mb-5">
                    <label
                      htmlFor={`serial_number_${index}`}
                      className="block first:uppercase tracking-wide text-lg mb-1"
                    >
                      Numéro de série
                    </label>
                    <input
                      type="text"
                      id={`serial_number_${index}`}
                      value={bloc.serial_number}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "serial_number",
                          e.target.value
                        )
                      }
                      placeholder="Numéro de série de l'ordinateur"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[blue]"
                      ref={inputRefs.current[index].serial_number}
                    />
                  </div>
                </div>
              </div>
            </>
          ))}

          <div className="w-full flex justify-start">
            <div
              className="bg-[blue] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
              onClick={addBloc}
            >
              Ajouter ordinateur
            </div>
          </div>
        </div>
        <div className="flex justify-end my-5  w-[98%]">
          <button
            type="submit"
            disabled={boutLoading}
            htmlFor="send"
            className={`mt-3 cursor-pointer w-max flex justify-end bg-[blue] p-2 rounded ${
              boutLoading ? "opacity-45" : ""
            } text-white`}
          >
            Enregistre
          </button>
        </div>
      </form>
    </div>
  );
}

export default Ajouter;
