/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

function Detail() {
  const { id } = useParams();
  // const socket = io('http://localhost:5010');

  const [data, setData] = useState(null);
  const fethData = async () => {
    axios
      .get(
        `https://bakend-tp-app-web-telecom.abahs-jobconnect.online/ordinateur/list/${id}`
      )
      .then((response) => {
        setData(response.data.ordiExist);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };

  useEffect(() => {
    fethData();
  }, [id]);

  if (!data) {
    return (
      <div className="h-[85vh] w-full text-gray-500 flex items-center justify-center">
        <p>Aucune donnée trouvée.</p>
      </div>
    );
  }

  return (
    <div className="h-[85vh] px-2 overflow-x-hidden overflow-y-auto w-full">
      <div className="w-full flex justify-start items-center">
        <Link to="/" className="m-4  w-max">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#fea116"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </Link>
      </div>
      <div className="flex flex-col mb-5">
        <div className="w-full   shadow-md rounded-lg overflow-hidden">
          <div className="p-6">
            <h3 className="text-xl font-semibold mb-4">Détails d'ordinateur</h3>
            <div className={`grid grid-cols-1 rounded-md  gap-4`}>
              <img
                src={`https://bakend-tp-app-web-telecom.abahs-jobconnect.online/imageOrdi/${data.image}`}
                alt=""
                className="w-max h-auto object-cover border rounded-lg"
              />
              <div className="p-3 rounded">
                <p>
                  <span className="font-bold m-2">Nom:</span>{" "}
                  {data && data.name}
                </p>
                <p>
                  <span className="font-bold m-2">Brand:</span>{" "}
                  {data && data.brand}
                </p>
                <p>
                  <span className="font-bold m-2">Model:</span>{" "}
                  {data && data.model}
                </p>
                <p>
                  <span className="font-bold m-2">Type :</span>{" "}
                  {data && data.type}
                </p>
                <p>
                  <span className="font-bold m-2">CPU :</span>{" "}
                  {data && data.cpu}
                </p>
                <p>
                  <span className="font-bold m-2">RAM :</span>{" "}
                  {data && data.ram}
                </p>
                <p>
                  <span className="font-bold m-2">Stockage :</span>{" "}
                  {data && data.storage}
                </p>
                <p>
                  <span className="font-bold m-2">
                    Système d'exploitation :
                  </span>{" "}
                  {data && data.os}
                </p>
                <p>
                  <span className="font-bold m-2">Taille d'ecran:</span>{" "}
                  {data && data.screen_size}
                </p>
                <p>
                  <span className="font-bold m-2">Résolution:</span>{" "}
                  {data && data.resolution}
                </p>
                <p>
                  <span className="font-bold m-2">Connectivity:</span>{" "}
                  {data && data.connectivity}
                </p>
                <p>
                  <span className="font-bold m-2">battery:</span>{" "}
                  {data && data.battery}
                </p>
                <p>
                  <span className="font-bold m-2">Poids:</span>{" "}
                  {data && data.weight}
                </p>
                <p>
                  <span className="font-bold m-2">serial_number:</span>{" "}
                  {data && data.serial_number}
                </p>
                <p>
                  <span className="font-bold m-2">Créer:</span> Il y a{" "}
                  {new Date(data && data.createdAt).toLocaleString("FR-fr")}{" "}
                  <span className="text-xs">
                    donc (
                    {new Date(data && data.createdAt).toLocaleString("fr-FR")})
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Detail;
