/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";

function Modifier() {
  const [mobile11, SetMobile11] = useState(window.innerWidth < 501);
  const navigate = useNavigate();
  const navig = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    const handleResize = () => {
      SetMobile11(window.innerWidth < 501);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [mobile, SetMobile] = useState(window.innerWidth <= 640);
  const [boutLoading, setBoutLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      SetMobile(window.innerWidth <= 640);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [imageExista, SetimageExista] = useState(null);
  const [imageNew, SetimageNew] = useState(null);
  const inputRefsimageExista = useRef(null);

  const [name, Setname] = useState("");
  const inputRefsname = useRef(null);

  const [brand, Setbrand] = useState("");
  const inputRefsbrand = useRef(null);

  const [model, Setmodel] = useState("");
  const inputRefsmodel = useRef(null);

  const [type, Settype] = useState("");
  const inputRefstype = useRef(null);

  const [cpu, Setcpu] = useState("");
  const inputRefscpu = useRef(null);

  const [ram, Setram] = useState(0);
  const inputRefsram = useRef(null);

  const [storage, Setstorage] = useState("");
  const inputRefsstorage = useRef(null);

  const [os, Setos] = useState("");
  const inputRefsos = useRef(null);

  const [screen_size, Setscreen_size] = useState(0);
  const inputRefsscreen_size = useRef(null);

  const [resolution, Setresolution] = useState("");
  const inputRefsresolution = useRef(null);

  const [connectivity, Setconnectivity] = useState("");
  const inputRefsconnectivity = useRef(null);

  const [battery, Setbattery] = useState("");
  const inputRefsbattery = useRef(null);

  const [weight, Setweight] = useState("");
  const inputRefsweight = useRef(null);

  const [serial_number, Setserial_number] = useState("");
  const inputRefsserial_number = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name.trim() == "") {
      toast.warning("Le nom  est obligatoire !!");
      inputRefsname.current && inputRefsname.current.focus();
      return false;
    } else if (brand.trim() == "") {
      toast.warning("Le brand  est obligatoire !!");
      inputRefsbrand.current && inputRefsbrand.current.focus();
      return false;
    } else if (model.trim() == "") {
      toast.error("La model est obligatoire");
      inputRefsmodel.current && inputRefsmodel.current.focus();
      return false;
    } else if (type == "") {
      toast.warning("Le type est obligatoire !!");
      inputRefstype.current && inputRefstype.current.focus();
      return false;
    } else if (cpu.trim() == "") {
      toast.warning("Le cpu est obligatoire !!");
      inputRefscpu.current && inputRefscpu.current.focus();
      return false;
    } else if (ram == 0) {
      toast.warning("Le RAM est obligatoire !!");
      inputRefsram.current && inputRefsram.current.focus();
      return false;
    } else if (storage.trim() == "") {
      toast.warning("Le stockage est obligatoire !!");
      inputRefsstorage.current && inputRefsstorage.current.focus();
      return false;
    } else if (os.trim() == "") {
      toast.warning("Le os est obligatoire !!");
      inputRefsos.current && inputRefsos.current.focus();
      return false;
    } else if (screen_size == 0) {
      toast.warning("La taille d'ecrant est obligatoire !!");
      inputRefsscreen_size.current && inputRefsscreen_size.current.focus();
      return false;
    } else if (resolution.trim() == "") {
      toast.warning("La resolution est obligatoire !!");
      inputRefsresolution.current && inputRefsresolution.current.focus();
      return false;
    } else if (connectivity.trim() == "") {
      toast.warning("La connectivite est obligatoire !!");
      inputRefsconnectivity.current && inputRefsconnectivity.current.focus();
      return false;
    } else if (battery.trim() == "") {
      toast.warning("La baterie est obligatoire !!");
      inputRefsbattery.current && inputRefsbattery.current.focus();
      return false;
    } else if (weight == "") {
      toast.warning("Le poids est obligatoire !!");
      inputRefsweight.current && inputRefsweight.current.focus();
      return false;
    } else if (serial_number.trim() == "") {
      toast.warning("La numero de serie est obligatoire !!");
      inputRefsserial_number.current && inputRefsserial_number.current.focus();
      return false;
    }

    setBoutLoading(true);
    const formData = new FormData();

    if (imageNew) {
      formData.append("image", imageNew);
    }
    formData.append("name", name);
    formData.append("brand", brand);
    formData.append("model", model);
    formData.append("type", type);
    formData.append("cpu", cpu);
    formData.append("ram", ram);
    formData.append("storage", storage);
    formData.append("os", os);
    formData.append("screen_size", screen_size);
    formData.append("resolution", resolution);
    formData.append("connectivity", connectivity);
    formData.append("battery", battery);
    formData.append("weight", weight);
    formData.append("serial_number", serial_number);

    axios
      .put(
        `https://bakend-tp-app-web-telecom.abahs-jobconnect.online/ordinateur/list/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((repos) => {
        toast.success("La modification a été bien faite");
        setBoutLoading(false);
        navig("/");
      })
      .catch((error) => {
        console.log("error", error);
        setBoutLoading(false);
        toast.error("Il y a une erreur");
      });
  };

  useEffect(() => {
    axios
      .get(
        `https://bakend-tp-app-web-telecom.abahs-jobconnect.online/ordinateur/list/${id}`
      )
      .then((repos) => {
        const respos = repos.data.ordiExist;
        SetimageExista(respos.image);
        Setname(respos.name);
        Setbrand(respos.brand);
        Setmodel(respos.model);
        Settype(respos.type);
        Setcpu(respos.cpu);
        Setram(respos.ram);
        Setstorage(respos.storage);
        Setos(respos.os);
        Setscreen_size(respos.screen_size);
        Setresolution(respos.resolution);
        Setconnectivity(respos.connectivity);
        Setbattery(respos.battery);
        Setweight(respos.weight);
        Setserial_number(respos.serial_number);
      })
      .catch((error) => {
        console.log(
          "Erreur lors de la récupération des données  de l'emplye:",
          error
        );
      });
  }, [id]);

  return (
    <div className={`w-full overflow-y-auto   overflow-x-hidden h-[90vh]`}>
      <div className="flex w-full  flex-col">
        <Link to="/" className="m-4  w-max">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#fea116"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </Link>
        <form onSubmit={handleSubmit} className="flex flex-col items-center">
          <div className={`flex px-4 p-2  w-[99%] rounded-md max-sm:mt-2`}>
            <div className="justify-center w-full rounded-xl p-1 sm:p-2 mb-10  flex flex-col">
              <div className="flex flex-col items-center">
                <label
                  className={`w-full  border border-gray-300  options_personnalisation cursor-pointer  outline-none focus:border-[#fea116]  rounded-md p-2.5  bg-transparent `}
                  ref={inputRefsimageExista}
                  htmlFor={`id_photo`}
                >
                  Profil
                </label>
                <input
                  type="file"
                  accept=".jpeg,.png,.jpg,.gif"
                  className="w-full hidden mt-1"
                  id={`id_photo`}
                  onChange={(e) => {
                    SetimageNew(e.target.files[0]);
                    SetimageExista(null);
                  }}
                />

                <div className="flex w-full justify-start">
                  {imageNew !== null ? (
                    <div className="w-[15em] mt-[2.5em] overflow-hidden  relative  rounded-lg h-[15em] border border-gray-300">
                      <img
                        src={URL.createObjectURL(imageNew)}
                        className="object-cover w-full h-full"
                        alt="   "
                      />
                    </div>
                  ) : (
                    <div className="w-[15em] mt-[2.5em] overflow-hidden  relative  rounded-lg h-[15em] border border-gray-300">
                      <img
                        className="object-cover w-full h-full"
                        src={`https://bakend-tp-app-web-telecom.abahs-jobconnect.online/imageOrdi/${imageExista}`}
                        alt="  "
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-col mt-5">
                <label
                  htmlFor={`name`}
                  className="accomp font-serif first_letter:uppercase tracking_wide text-lg mb-1"
                >
                  Nom complet
                </label>
                <input
                  type="text"
                  id={`name`}
                  value={name}
                  onChange={(e) => Setname(e.target.value)}
                  className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#fea116]"
                  ref={inputRefsname}
                />
              </div>

              <div className="flex flex-col mt-5">
                <label
                  htmlFor={`brand`}
                  className="accomp font-serif first_letter:uppercase tracking_wide text-lg mb-1"
                >
                  Marque
                </label>
                <input
                  type="text"
                  id={`brand`}
                  value={brand}
                  onChange={(e) => Setbrand(e.target.value)}
                  className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#fea116]"
                  ref={inputRefsbrand}
                />
              </div>

              <div className="flex flex-col mt-5">
                <label
                  htmlFor={`model`}
                  className="first-letter:uppercase tracking-wide text-lg mb-1"
                >
                  Modèle
                </label>
                <input
                  id={`model`}
                  value={model}
                  onChange={(e) => Setmodel(e.target.value)}
                  className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#fea116]"
                  ref={inputRefsmodel}
                />
              </div>

              <div className="flex flex-col mt-5">
                <label
                  htmlFor={`type`}
                  className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                >
                  Choisissez un type
                </label>
                <select
                  id={`type`}
                  value={type}
                  onChange={(e) => Settype(e.target.value)}
                  className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#fea116]"
                  ref={inputRefstype}
                >
                  <option className="text-black" value="Bureau">
                    Bureau
                  </option>
                  <option className="text-black" value="Portable">
                    Portable
                  </option>
                </select>
              </div>

              <div className="flex flex-col mt-5">
                <label
                  htmlFor={`cpu`}
                  className="first-letter:uppercase tracking-wide text-lg mb-1"
                >
                  Processeur
                </label>
                <input
                  type="text"
                  id={`cpu`}
                  value={cpu}
                  onChange={(e) => Setcpu(e.target.value)}
                  className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#fea116]"
                  ref={inputRefscpu}
                />
              </div>

              <div className="flex flex-col mt-5">
                <label
                  htmlFor={`ram`}
                  className="first-letter:uppercase tracking-wide text-lg mb-1"
                >
                  RAM (en Go)
                </label>
                <input
                  type="number"
                  id={`ram`}
                  value={ram}
                  onChange={(e) => Setram(e.target.value)}
                  className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#fea116]"
                  ref={inputRefsram}
                />
              </div>

              <div className="flex flex-col mt-5">
                <label
                  htmlFor={`storage`}
                  className="first-letter:uppercase tracking-wide text-lg mb-1"
                >
                  Stockage
                </label>
                <input
                  type="text"
                  id={`storage`}
                  value={storage}
                  onChange={(e) => Setstorage(e.target.value)}
                  className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#fea116]"
                  ref={inputRefsstorage}
                />
              </div>

              <div className="flex flex-col mt-5">
                <label
                  htmlFor={`os`}
                  className="first-letter:uppercase tracking-wide text-lg mb-1"
                >
                  Système d'exploitation
                </label>
                <input
                  type="text"
                  id={`os`}
                  value={os}
                  onChange={(e) => Setos(e.target.value)}
                  className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#fea116]"
                  ref={inputRefsos}
                />
              </div>

              <div className="flex flex-col mt-5">
                <label
                  htmlFor={`screen_size`}
                  className="first-letter:uppercase tracking-wide text-lg mb-1"
                >
                  Taille d'écran (en pouces)
                </label>
                <input
                  type="number"
                  id={`screen_size`}
                  value={screen_size}
                  onChange={(e) => Setscreen_size(e.target.value)}
                  className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#fea116]"
                  ref={inputRefsscreen_size}
                />
              </div>

              <div className="flex flex-col mt-5">
                <label
                  htmlFor={`resolution`}
                  className="first-letter:uppercase tracking-wide text-lg mb-1"
                >
                  Résolution
                </label>
                <input
                  type="text"
                  id={`resolution`}
                  value={resolution}
                  onChange={(e) => Setresolution(e.target.value)}
                  className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#fea116]"
                  ref={inputRefsresolution}
                />
              </div>

              <div className="flex flex-col mt-5">
                <label
                  htmlFor={`connectivity`}
                  className="first-letter:uppercase tracking-wide text-lg mb-1"
                >
                  Connectivité
                </label>
                <input
                  type="text"
                  id={`connectivity`}
                  value={connectivity}
                  onChange={(e) => Setconnectivity(e.target.value)}
                  className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#fea116]"
                  ref={inputRefsconnectivity}
                />
              </div>

              <div className="flex flex-col mt-5">
                <label
                  htmlFor={`battery`}
                  className="first-letter:uppercase tracking-wide text-lg mb-1"
                >
                  Batterie
                </label>
                <input
                  type="text"
                  id={`battery`}
                  value={battery}
                  onChange={(e) => Setbattery(e.target.value)}
                  className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#fea116]"
                  ref={inputRefsbattery}
                />
              </div>

              <div className="flex flex-col mt-5">
                <label
                  htmlFor={`weight`}
                  className="first-letter:uppercase tracking-wide text-lg mb-1"
                >
                  Poids (en kg)
                </label>
                <input
                  type="number"
                  id={`weight`}
                  value={weight}
                  onChange={(e) => Setweight(e.target.value)}
                  className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#fea116]"
                  ref={inputRefsweight}
                />
              </div>

              <div className="flex flex-col mt-5">
                <label
                  htmlFor={`serial_number`}
                  className="first-letter:uppercase tracking-wide text-lg mb-1"
                >
                  Numéro de série
                </label>
                <input
                  type="text"
                  id={`serial_number`}
                  value={serial_number}
                  onChange={(e) => Setserial_number(e.target.value)}
                  className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#fea116]"
                  ref={inputRefsserial_number}
                />
              </div>
            </div>
          </div>

          <hr />
          <div className="flex w-full px-10 mt-10 mb-10">
            <div className="w-full flex justify-end mt-4">
              <button
                disabled={boutLoading}
                type="submit"
                className={`text-white bg-[blue] ${
                  boutLoading ? "opacity-55" : "opacity-100"
                } rounded-md px-4 py-2`}
              >
                Modifier
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Modifier;
