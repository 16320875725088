/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Routes, Route, useNavigate } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "rsuite/dist/rsuite.min.css";
import { ToastContainer } from "react-toastify";
import AcceuilleComponent from "./Compenent/Acceuille/AcceuilleComponent";
import Modifier from "./Compenent/Acceuille/Modifier";
import Detail from "./Compenent/Acceuille/Detail";
import Ajouter from "./Compenent/Acceuille/Ajouter";
import Header from "./Compenent/Acceuille/Header";
import { useEffect, useState } from "react";
import { Modal } from "rsuite";
import UsersFormuaireLogin from "./Compenent/Acceuille/UsersFormuaireLogin";
import HomePage from "./Compenent/Acceuille/AcceuilLogin";
import UsersFormuaireRegistrant from "./Compenent/Acceuille/UsersFormuaireRegistrant";
import axios from "axios";

//.......................... Visiteurs...............................
function App() {
  const [isTelecom, SetTelecom] = useState(false);
  const [isLogine, SetLogine] = useState(false);
  const [isRegistrer, SetRegistrer] = useState(false);

  const tokensExist = localStorage.getItem("Tokeni_TP");
  const UserId = localStorage.getItem("userId_TP");
  const tokenUtile = {
    headers: { Authorization: `Bearer ${tokensExist}` },
  };

  const [compte, SetCompte] = useState({});

  const fethData = async () => {
    await axios
      .get(
        `https://bakend-tp-app-web-telecom.abahs-jobconnect.online/user/list/${UserId}`
      )
      .then((reponse) => {
        SetCompte(reponse.data.user);
      })
      .catch((error) => {
        console.log("error data compte", error);
      });
  };

  useEffect(() => {
    fethData();
  }, [UserId]);

  useEffect(() => {
    if (tokensExist) {
      SetTelecom(true);
    } else {
      SetTelecom(false);
    }
  }, [tokensExist]);

  return isTelecom ? (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<AcceuilleComponent compte={compte} />} />
        <Route path="/ajouter" element={<Ajouter tokenUtile={tokenUtile} />} />
        <Route path="/mod/:id" element={<Modifier />} />
        <Route path="/detail/:id" element={<Detail />} />
      </Routes>
      <ToastContainer position="bottom-left" />
    </>
  ) : (
    <>
      <HomePage SetLogine={SetLogine} />
      <Modal
        open={isLogine}
        va
        onClose={() => SetLogine(false)}
        placement="right"
      >
        <Modal.Header>
          <Modal.Title className="text-center uppercase">
            Se connecter
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UsersFormuaireLogin
            SetRegistrer={SetRegistrer}
            SetLogine={SetLogine}
          />
        </Modal.Body>
      </Modal>

      <Modal
        open={isRegistrer}
        overflow={false}
        onClose={() => SetRegistrer(false)}
        placement="right"
      >
        <Modal.Header>
          <Modal.Title>S'inscrire</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UsersFormuaireRegistrant
            SetLogine={SetLogine}
            SetRegistrer={SetRegistrer}
          />
        </Modal.Body>
      </Modal>

      <ToastContainer position="bottom-left" />
    </>
  );
}

export default App;
