import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
const UsersFormulaireRegistrant = ({ SetLogine, SetRegistrer }) => {
  const [nom, Setnom] = useState("");
  const [telephone, Settelephone] = useState("");
  const [email, Setemail] = useState("");
  const [password, Setpassword] = useState("");
  const [boutLoading, SetboutLoading] = useState(false);
  const [errorMessage, SetErrorMessage] = useState("");
  const [showEye, SetshowEye] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    SetErrorMessage(""); // Réinitialiser le message d'erreur

    // Validation des champs
    if (!nom || !telephone || !email || !password) {
      SetErrorMessage("Tous les champs doivent être remplis.");
      return;
    }

    SetboutLoading(true);

    const data = {
      name: nom,
      telephone,
      email,
      password,
    };
    SetboutLoading(true);
    try {
      await axios.post(
        "https://bakend-tp-app-web-telecom.abahs-jobconnect.online/user/resgister_user",
        data
      );
      Setnom("");
      Settelephone("");
      Setemail("");
      Setpassword("");
      SetLogine(true);
      SetRegistrer(false);
      SetboutLoading(false);
      toast.success("Vous etes ajoute avec success");
    } catch (error) {
      SetboutLoading(false);
      if (error.response.status === 409) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Il y a une erreur");
        console.error("Erreur:", error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col items-center">
      {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>}
      <div className={`flex p-2 w-[99%] rounded-md max-sm:mt-2`}>
        <div className="justify-center w-full rounded-xl p-1 sm:p-2  flex flex-col">
          <div className="flex flex-col mt-5">
            <label
              htmlFor={`nom`}
              className="accomp font-serif first_letter:uppercase tracking_wide text-lg mb-1"
            >
              Nom complet
            </label>
            <input
              type="text"
              id={`nom`}
              value={nom}
              onChange={(e) => Setnom(e.target.value)}
              className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[blue]"
            />
          </div>

          <div className="flex flex-col mt-5">
            <label
              htmlFor={`telephone`}
              className="first-letter:uppercase tracking-wide text-lg mb-1"
            >
              Téléphone
            </label>
            <input
              type="text"
              id={`telephone`}
              value={telephone}
              onChange={(e) => Settelephone(e.target.value)}
              className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[blue]"
            />
          </div>

          <div className="flex flex-col mt-5">
            <label
              htmlFor={`email`}
              className="first-letter:uppercase tracking-wide text-lg mb-1"
            >
              Email
            </label>
            <input
              type="email"
              id={`email`}
              value={email}
              onChange={(e) => Setemail(e.target.value)}
              className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[blue]"
            />
          </div>
          <div className="w-full relative mt-10">
            <label className="block mb-1 text-sm font-medium ">
              Mot de passe{" "}
            </label>
            <div className="flex relative">
              <input
                onChange={(e) => Setpassword(e.target.value)}
                type={`${showEye ? "text" : "password"}`}
                className={`w-full  border  pr-8 outline-none focus:border-[#fea116]   rounded-md p-2.5  bg-transparent  border-gray-200`}
                placeholder="Xxxxx@12"
              />
              <div
                onClick={() => SetshowEye(!showEye)}
                className={`absolute cursor-pointer w-7 right-1 h-[97%] flex justify-center items-center`}
              >
                {showEye ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-eye rounded hover:bg-gray-200 transition-all p-2 "
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                    <path d="M8 5.5a2.5  2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-eye-slash  rounded hover:bg-gray-200 transition-all p-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                    <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full p-5 mt-2">
        <div className="w-full flex justify-end">
          <button
            disabled={boutLoading}
            type="submit"
            className={`text-white bg-[blue] ${
              boutLoading ? "opacity-55" : "opacity-100"
            } rounded-md px-4 py-2`}
          >
            Créer
          </button>
        </div>
      </div>

      <div>
        <p className="text-gray-700">
          Vous avez déjà un compte ?
          <button
            onClick={() => {
              SetLogine(true);
              SetRegistrer(false);
            }}
            className="text-blue-500 underline ml-1"
          >
            Cliquez ici.
          </button>
        </p>
      </div>
    </form>
  );
};

export default UsersFormulaireRegistrant;
