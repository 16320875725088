import React from 'react';

const HomePage = ({ SetLogine }) => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className="text-2xl font-bold mb-4 w-full text-center">Bienvenue sur notre plateforme !</div>
            <p className="sm:text-lg mb-8 text-center max-w-md">
                Rejoignez-nous pour découvrir un monde d'opportunités et de connaissances.
                Connectez-vous dès maintenant pour maîtriser l'enregistrement
                des ordinateurs de votre entreprise.
            </p>
            <button
                onClick={() => SetLogine(true)}
                className="px-6 py-3 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
            >
                Se connecter
            </button>
        </div>
    );
};

export default HomePage;