/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

function AcceuilleComponent({ compte }) {
  const { id } = useParams();
  const [userData, setUserData] = useState([]);
  const [sortedColumn, setSortedColumn] = useState("dateInscri");
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [query, setQuery] = useState("");

  const handleSort = (column) => {
    if (column === sortedColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortOrder("asc");
      setSortedColumn(column);
    }
  };

  const sortData = (data, column, sortOrder) => {
    return data.slice().sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortOrder === "desc"
          ? aValue.localeCompare(bValue, undefined, { sensitivity: "base" })
          : bValue.localeCompare(aValue, undefined, { sensitivity: "base" });
      }

      if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
      if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });
  };

  const sortedData = sortData(userData, sortedColumn, sortOrder);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://bakend-tp-app-web-telecom.abahs-jobconnect.online/ordinateur/list`,
        {
          params: { page: page, limit: limit, query: query },
        }
      );

      const totalPage = response.data.totalPage;
      setTotalPages(totalPage);
      const userData = response.data.ordinateur;
      Promise.all(
        userData.map(async (data) => {
          const userResponse = await axios.get(
            `https://bakend-tp-app-web-telecom.abahs-jobconnect.online/user/list/${data.user_id}`
          );
          const userDatas = userResponse.data.user;
          const user_ids = { nom_user: userDatas.name, id_users: userDatas.id };
          return { ...user_ids, ...data };
        })
      ).then((resp) => {
        const sortedlivr = resp.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setUserData(sortedlivr);
      });
    } catch (error) {
      console.log("Erreur lors de la récupération des ordinateurs :", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, limit, query, id]);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };
  const highlightText = (text, query) => {
    if (!query || typeof text !== "string") {
      return text;
    }
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} style={{ color: "blue", fontWeight: "bold" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };
  const HudleReaserch = () => {
    setQuery("");
    setPage(1);
    fetchData();
  };
  const DeleteOrdinateur = (id, name) => {
    Swal.fire({
      title: "Êtes-vous sûr?",
      html: `Vous ne pourrez pas récupérer le compte de <strong style="font-size: 1.5em; display:block">${name}</strong> en arrière!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, supprimer!",
      confirmButtonColor: "red",
      cancelButtonText: "Non, annuler!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `https://bakend-tp-app-web-telecom.abahs-jobconnect.online/ordinateur/list/${id}`
          )
          .then(() => {
            setUserData(userData.filter((val) => val.id !== id));
            toast.success("L'ordinateurs a été supprimé avec succès.");
          })
          .catch((error) => {
            toast.error(
              "Il y a une erreur lors de la suppression de l'ordinateur"
            );
            console.log("il y a une erreur", error);
          });
      }
    });
  };

  console.log("sortedData", sortedData);
  const navig = useNavigate();
  return (
    <div className="overflow-x-auto p-8">
      <h1 className="text-2xl font-bold text-gray-800">
        Liste des Ordinateurs
      </h1>
      <div className="flex mt-3 justify-between items-center mb-6">
        <div className="flex items-center mb-2   flex-wrap sm:mt-2 mt-1 w-full justify-center">
          <div className={`flex px-4  p-2  w-[99%] rounded-md max-sm:mt-2`}>
            <div
              className={`w-full justify-between flex flex-wrap items-center`}
            >
              <div className="flex items-center">
                <div>
                  <select
                    value={limit}
                    onChange={(e) => {
                      setLimit(parseInt(e.target.value));
                      setPage(1);
                    }}
                    className="bg-transparent border border-[blue] px-1 sm:px-2 cursor-pointer h-10 sm:text-lg first-letter:uppercase rounded-lg   block"
                  >
                    <option className="text-black" value={1}>
                      1 par page
                    </option>
                    <option className="text-black" value={5}>
                      5 par page
                    </option>
                    <option className="text-black" value={10}>
                      10 par page
                    </option>
                    <option className="text-black" value={20}>
                      20 par page
                    </option>
                    <option className="text-black" value={50}>
                      50 par page
                    </option>
                    <option className="text-black" value={100}>
                      100 par page
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex items-center">
                <div className="flex relative">
                  <input
                    value={query}
                    onChange={handleInputChange}
                    className={`w-full  border  pr-8 outline-none focus:border-[blue]  rounded-md p-2.5  bg-transparent  border-gray-200`}
                    placeholder="Recherche le client"
                  />

                  {query && (
                    <div
                      onClick={() => HudleReaserch()}
                      className={` absolute cursor-pointer w-7 h-[95%] right-1  flex justify-center items-center`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="rounded  text-red-600  hover:bg-red-100"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => navig("/ajouter")}
          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
        >
          Ajouter
        </button>
      </div>
      <table className="min-w-full table-auto bg-white shadow-lg rounded-lg">
        <thead className="bg-blue-300 text-white">
          <tr>
            <th className="p-3 text-left font-semibold border-r">Image</th>
            <th className="p-3 text-left font-semibold border-r">Nom</th>
            <th className="p-3 text-left font-semibold border-r">Marque</th>
            <th className="p-3 text-left font-semibold border-r">Modèle</th>
            <th className="p-3 text-left font-semibold border-r">CPU</th>
            <th className="p-3 text-left font-semibold border-r">RAM</th>
            <th className="p-3 text-left font-semibold border-r">Stockage</th>
            <th className="p-3 text-left font-semibold border-r">
              Enregistre avec
            </th>
            <th className="p-3 text-left font-semibold border-r w-[10em]">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData &&
            sortedData.length > 0 &&
            sortedData.map((computer, index) => (
              <tr key={index} className="border-b hover:bg-gray-50">
                <td className="p-2 border-r">
                  <img
                    src={`https://bakend-tp-app-web-telecom.abahs-jobconnect.online/imageOrdi/${computer.image}`}
                    alt=""
                    className="w-10 h-10 object-cover border rounded-lg"
                  />
                </td>
                <td className="p-2 border-r">
                  {highlightText(computer.name, query)}
                </td>
                <td className="p-2 border-r">
                  {highlightText(computer.brand, query)}
                </td>
                <td className="p-2 border-r">
                  {highlightText(computer.model, query)}
                </td>
                <td className="p-2 border-r">
                  {highlightText(computer.cpu, query)}
                </td>
                <td className="p-2 border-r">
                  {highlightText(computer.ram, query)}
                </td>
                <td className="p-2 border-r">
                  {highlightText(computer.storage, query)}
                </td>
                <td className="p-2 border-r">
                  {computer.user_id == compte.id ? (
                    <>Vous</>
                  ) : (
                    computer.nom_user
                  )}
                </td>
                <td className="p-2 border-r space-x-1 flex">
                  <button
                    onClick={() => navig(`/detail/${computer.id}`)}
                    className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
                  >
                    Détails
                  </button>
                  <button
                    disabled={computer.user_id != compte.id}
                    onClick={() => navig(`/mod/${computer.id}`)}
                    className={`bg-yellow-500 text-white p-2 rounded-md hover:bg-yellow-600 ${
                      computer.user_id != compte.id ? "opacity-50" : null
                    }`}
                  >
                    Modifier
                  </button>
                  <button
                    disabled={computer.user_id != compte.id}
                    onClick={() => DeleteOrdinateur(computer.id, computer.name)}
                    className={`bg-red-500 text-white p-2 rounded-md hover:bg-red-600 ${
                      computer.user_id != compte.id ? "opacity-50" : null
                    }`}
                  >
                    Supprimer
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {sortedData.length == 0 && (
        <div className="w-full mt-5 text-center text-lg">
          Aucun ordinateur trouvé correspond aux:
          <span className="text-[blue] ml-3 font-serif">{query}</span>{" "}
        </div>
      )}

      <div className="flex justify-between px-3 my-4 flex-wrap">
        <button
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
          className="py-2 px-4 bg-[#0000ff7a] hover:bg-[bluea1] text-[blue] font-semibold rounded-lg disabled:opacity-50 mb-2 sm:mb-0"
        >
          Précédent
        </button>
        <div className="flex flex-wrap ">
          Page {page} sur {totalPages}
        </div>
        <button
          disabled={page === totalPages}
          onClick={() => setPage(page + 1)}
          className="py-2 px-4 bg-[#0000ff7a] hover:bg-[#0000ff7a] text-[blue] font-semibold rounded-lg disabled:opacity-50 mb-2 sm:mb-0"
        >
          Suivant
        </button>
      </div>
    </div>
  );
}

export default AcceuilleComponent;
