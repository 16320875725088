import React from 'react'
import { useNavigate } from 'react-router-dom'

function Header() {
    const nav = useNavigate()
    const HundleDeconnexion = () => {
        localStorage.removeItem('Tokeni_TP')
        localStorage.removeItem('userId_TP')
        nav('/')
        window.location.reload()
    }
    return (
        <header className="bg-blue-600 text-white py-4 shadow-lg">
            <div className="container mx-auto px-4 flex justify-between items-center">
                <h1 onClick={() => nav('/')} className="text-2xl cursor-pointer font-bold">Gestion des Ordinateurs</h1>
                <div className='sm:flex items-center'>
                    <div className="flex mr-2">
                        Universite du Burundi
                    </div>
                    <div onClick={HundleDeconnexion} className="flex border cursor-pointer bg-red-400 text-white rounded-md p-2">
                        Deconnecter
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header